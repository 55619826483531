"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateDistance = exports.GlobeMeasurements = void 0;
const GlobalStore_1 = require("@/store/GlobalStore");
const GlobeRenderer_1 = require("./GlobeRenderer");
const Disposable_1 = require("@/utils/Disposable");
const tsyringe_1 = require("tsyringe");
const GlobeContext_1 = require("./GlobeContext");
let GlobeMeasurements = class GlobeMeasurements extends Disposable_1.Disposable {
    constructor(globalStore, globeRenderer, globeContext) {
        super();
        this.globalStore = globalStore;
        this.globeRenderer = globeRenderer;
        this.globeContext = globeContext;
        this.subscribe(globalStore, (s) => (Object.assign({}, s.measure)), (measure) => this.update(measure));
        const cb = (ev) => {
            this.globalStore.setMouseCoordinate(this.coordinates(ev));
        };
        this.globeContext.canvas.addEventListener("mousemove", cb);
        this.defer(() => this.globeContext.canvas.removeEventListener("mousemove", cb));
    }
    update(measure) {
        if (!measure.enabled || measure.coordinateA == null || measure.coordinateB == null) {
            return this.globeRenderer.setMeasureLine(null);
        }
        const { coordinateA, coordinateB } = measure;
        const pointMid = {
            x: (coordinateA.point.x + coordinateB.point.x) / 2,
            y: (coordinateA.point.y + coordinateB.point.y) / 2,
            z: (coordinateA.point.z + coordinateB.point.z) / 2,
        };
        const sphereMid = this.cartToSphere(pointMid.x, pointMid.y, pointMid.z);
        const sphereA = this.cartToSphere(coordinateA.point.x, coordinateA.point.y, coordinateA.point.z);
        const sphereB = this.cartToSphere(coordinateB.point.x, coordinateB.point.y, coordinateB.point.z);
        const newA = this.sphereToCart(101, sphereA.theta, sphereA.phi);
        const newB = this.sphereToCart(101, sphereB.theta, sphereB.phi);
        const tempMid = this.sphereToCart(101, sphereMid.theta, sphereMid.phi);
        const shortMid = {
            x: (newA.x + tempMid.x) / 2,
            y: (newA.y + tempMid.y) / 2,
            z: (newA.z + tempMid.z) / 2,
        };
        const newDist = Math.sqrt(Math.pow(shortMid.x - newA.x, 2) + Math.pow(shortMid.y - newA.y, 2) + Math.pow(shortMid.z - newA.z, 2));
        const newSagitta = 101 - Math.sqrt(Math.pow(101, 2) - Math.pow(newDist, 2)) + 101;
        const smallMid = this.cartToSphere(shortMid.x, shortMid.y, shortMid.z);
        const newSmallMid = this.sphereToCart(newSagitta, smallMid.theta, smallMid.phi);
        const curve = new THREE.QuadraticBezierCurve3(new THREE.Vector3(newA.x, newA.y, newA.z), new THREE.Vector3(newSmallMid.x, newSmallMid.y, newSmallMid.z), new THREE.Vector3(tempMid.x, tempMid.y, tempMid.z));
        const shortMid2 = {
            x: (tempMid.x + newB.x) / 2,
            y: (tempMid.y + newB.y) / 2,
            z: (tempMid.z + newB.z) / 2,
        };
        const smallMid2 = this.cartToSphere(shortMid2.x, shortMid2.y, shortMid2.z);
        const newSmallMid2 = this.sphereToCart(newSagitta, smallMid2.theta, smallMid2.phi);
        const curve2 = new THREE.QuadraticBezierCurve3(new THREE.Vector3(tempMid.x, tempMid.y, tempMid.z), new THREE.Vector3(newSmallMid2.x, newSmallMid2.y, newSmallMid2.z), new THREE.Vector3(newB.x, newB.y, newB.z));
        const path = new THREE.CurvePath();
        path.add(curve);
        path.add(curve2);
        const curveMaterial = new THREE.LineBasicMaterial({ color: 0xff0000 });
        const curvedline = new THREE.Line(new THREE.BufferGeometry().setFromPoints([...curve.getPoints(50), ...curve2.getPoints(50)]), curveMaterial);
        curvedline.renderOrder = 1;
        this.globeRenderer.setMeasureLine(curvedline);
    }
    coordinates(event) {
        const raycaster = new THREE.Raycaster();
        const mouse = new THREE.Vector2((event.clientX / window.innerWidth) * 2 - 1, -(event.clientY / window.innerHeight) * 2 + 1);
        raycaster.setFromCamera(mouse, this.globeRenderer.camera);
        const intersects = raycaster.intersectObject(this.globeRenderer.sphere);
        if (intersects.length > 0) {
            const point = intersects[0].point;
            const uv = intersects[0].uv;
            const map = this.globeRenderer.sphere.material.length > 0 ? this.globeRenderer.sphere.material[0].map : null;
            const mapUV = uv != null && map != null ? map.transformUv(uv) : null;
            return {
                point: { x: point.x, y: point.y, z: point.z },
                geo: (() => {
                    if (mapUV == null)
                        return null;
                    const lat = 90 - mapUV.y * 180;
                    let long = mapUV.x * 360;
                    if (long > 180) {
                        long = long - 360;
                    }
                    return { lat, long };
                })(),
            };
        }
        return null;
    }
    cartToSphere(x, y, z) {
        var r = Math.sqrt(Math.pow(x, 2) + Math.pow(y, 2) + Math.pow(z, 2));
        var theta = Math.acos(z / r);
        if (x > 0)
            var phi = Math.atan(y / x);
        else
            var phi = Math.PI + Math.atan(y / x);
        return { r, theta, phi };
    }
    sphereToCart(r, theta, phi) {
        var x = r * Math.sin(theta) * Math.cos(phi);
        var y = r * Math.sin(theta) * Math.sin(phi);
        var z = r * Math.cos(theta);
        return { x, y, z };
    }
};
exports.GlobeMeasurements = GlobeMeasurements;
exports.GlobeMeasurements = GlobeMeasurements = __decorate([
    (0, tsyringe_1.singleton)(),
    __metadata("design:paramtypes", [typeof (_a = typeof GlobalStore_1.GlobalStore !== "undefined" && GlobalStore_1.GlobalStore) === "function" ? _a : Object, typeof (_b = typeof GlobeRenderer_1.GlobeRenderer !== "undefined" && GlobeRenderer_1.GlobeRenderer) === "function" ? _b : Object, typeof (_c = typeof GlobeContext_1.GlobeContext !== "undefined" && GlobeContext_1.GlobeContext) === "function" ? _c : Object])
], GlobeMeasurements);
function calculateDistance(measure) {
    const { coordinateA, coordinateB, radius } = measure;
    if (coordinateA == null || coordinateB == null)
        return 0;
    var w = Math.sqrt(Math.pow(coordinateA.point.x - coordinateB.point.x, 2) +
        Math.pow(coordinateA.point.y - coordinateB.point.y, 2) +
        Math.pow(coordinateA.point.z - coordinateB.point.z, 2));
    var distance = 2 * radius * Math.asin(w / (2 * radius));
    distance = (distance / 100) * radius;
    return distance;
}
exports.calculateDistance = calculateDistance;

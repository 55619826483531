"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.GlobeRenderer = void 0;
const GlobeContext_1 = require("./GlobeContext");
const tsyringe_1 = require("tsyringe");
const GLOBE_POLAR_SCALE = CONFIG.polarScale;
let GlobeRenderer = class GlobeRenderer {
    constructor(globeContext) {
        this.globeContext = globeContext;
        this.measureLine = null;
        this.renderer = new THREE.WebGLRenderer({
            canvas: globeContext.canvas,
            antialias: true,
            preserveDrawingBuffer: true,
            alpha: true,
        });
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color(0x0f0f0f);
        this.camera = new THREE.PerspectiveCamera(5, window.innerWidth / window.innerHeight, 0.1, 10000);
        this.camera.position.z = 3000;
        this.lightCamera = new THREE.PerspectiveCamera(5, window.innerWidth / window.innerHeight, 0.1, 6000);
        this.renderer.setClearColor(0x000000, 0);
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        this.controls = new THREE.OrbitControls(this.camera, this.renderer.domElement);
        // Light
        this.light = new THREE.DirectionalLight(0xffffff, 1);
        this.lightAmbient = new THREE.AmbientLight(0x404040);
        this.light.position.set(0, 0, 0);
        this.lightCamera.position.z = 6000;
        this.lightCamera.add(this.light);
        this.camera.add(this.lightCamera);
        this.scene.add(this.lightAmbient);
        this.scene.add(this.camera);
        // Sphere
        this.sphereGeometry = new THREE.SphereGeometry(100, 100, 100);
        this.sphereGeometry.scale(1, GLOBE_POLAR_SCALE, 1);
        this.sphereGeometry.addGroup(0, Infinity, 0);
        this.defaultMaterial = new THREE.MeshPhongMaterial({ color: 0x00ee00, wireframe: true, transparent: true });
        this.materials = [this.defaultMaterial];
        this.sphere = new THREE.Mesh(this.sphereGeometry, this.materials);
        this.scene.add(this.sphere);
        // Second sphere to be used for drawing tool overlay
        this.drawGeo = new THREE.SphereGeometry(100, 100, 100);
        this.drawMat = new THREE.MeshPhongMaterial({ transparent: true });
        this.drawSphere = new THREE.Mesh(this.drawGeo, this.drawMat);
        this.drawSphere.visible = false;
        this.scene.add(this.drawSphere);
        this.gridCanvas = document.createElement("canvas");
        this.gridCanvasCtx = this.gridCanvas.getContext("2d");
        // Pole Line
        this.lineMaterial = new THREE.LineBasicMaterial({ color: 0x0000ff });
        this.lineGeometry = new THREE.BufferGeometry().setFromPoints([
            new THREE.Vector3(0, -150, 0),
            new THREE.Vector3(0, 150, 0),
        ]);
        this.line = new THREE.Line(this.lineGeometry, this.lineMaterial);
        this.line.visible = false;
        this.scene.add(this.line);
        this.controls.addEventListener("change", this.render.bind(this));
    }
    render() {
        this.renderer.autoClear = false;
        this.renderer.clear();
        this.renderer.clearDepth();
        this.renderer.render(this.scene, this.camera);
    }
    setVisible(visible) {
        this.globeContext.canvas.hidden = !visible;
    }
    setGlobeTextures(args) {
        const { mapTextures, heightMapTexture, bumpScale } = args;
        this.materials = [];
        const maxAnisotropy = this.renderer.capabilities.getMaxAnisotropy();
        this.sphereGeometry.clearGroups();
        for (let i = 0; i < mapTextures.length; i++) {
            mapTextures[i].anisotropy = maxAnisotropy;
            mapTextures[i].minFilter = THREE.LinearFilter;
            const material = new THREE.MeshPhongMaterial({ map: mapTextures[i], shininess: 6, transparent: i > 0 });
            material.bumpMap = heightMapTexture;
            material.bumpScale = bumpScale;
            material.shininess = 6;
            material.needsUpdate = true;
            this.materials.push(material);
        }
        if (this.materials.length == 0) {
            this.materials = [this.defaultMaterial];
        }
        for (let i = 0; i < this.materials.length; i++) {
            this.sphereGeometry.addGroup(0, Infinity, i);
        }
        this.sphere.material = this.materials;
        this.sphereGeometry.buffersNeedUpdate = true;
        this.sphereGeometry.uvsNeedUpdate = true;
        this.render();
    }
    setPoleLineVisibility(visible) {
        this.line.visible = visible;
        this.render();
    }
    setGraticuleProperties(props) {
        /**
         * This function generates a Graticule by:
         * - Creating an in-memory canvas element
         * - Using d3 to draw a grid with the specified characteristics
         * - Loading the canvas result as a texture
         * - Using the texture in the Material for the Sphere
         */
        if (!props.visible) {
            this.drawSphere.visible = false;
            this.render();
            return;
        }
        const width = window.innerWidth - 350;
        const height = width / 2;
        this.gridCanvas.width = width;
        this.gridCanvas.height = height;
        this.gridCanvasCtx.clearRect(0, 0, width, height);
        const projection = d3.geo
            .equirectangular()
            .scale(width / 2 / Math.PI)
            .translate([width / 2, height / 2]);
        const graticule = d3.geo.graticule();
        graticule.step([props.meridianIntervalDegrees, props.parallelIntervalDegrees]);
        const path = d3.geo.path().projection(projection).context(this.gridCanvasCtx);
        this.gridCanvasCtx.beginPath();
        path(graticule());
        this.gridCanvasCtx.lineWidth = 0.5;
        this.gridCanvasCtx.strokeStyle = props.color;
        this.gridCanvasCtx.stroke();
        const texture = new THREE.Texture(this.gridCanvas);
        texture.needsUpdate = true;
        this.drawSphere.visible = true;
        this.drawSphere.material = new THREE.MeshPhongMaterial({ map: texture, shininess: 6, transparent: true });
        this.drawGeo.buffersNeedUpdate = true;
        this.drawGeo.uvsNeedUpdate = true;
        this.render();
    }
    setLightIntensity(intensity) {
        this.light.intensity = intensity;
        this.render();
    }
    setHeightMapBumpScale(bumpScale) {
        for (let i = 0; i < this.materials.length; i++) {
            this.materials[i].bumpScale = bumpScale;
        }
        this.render();
    }
    setMeasureLine(measureLine) {
        if (this.measureLine !== measureLine) {
            if (this.measureLine != null) {
                this.scene.remove(this.measureLine);
            }
            if (measureLine != null) {
                this.scene.add(measureLine);
            }
            this.measureLine = measureLine;
            this.render();
        }
    }
};
exports.GlobeRenderer = GlobeRenderer;
exports.GlobeRenderer = GlobeRenderer = __decorate([
    (0, tsyringe_1.singleton)(),
    __metadata("design:paramtypes", [typeof (_a = typeof GlobeContext_1.GlobeContext !== "undefined" && GlobeContext_1.GlobeContext) === "function" ? _a : Object])
], GlobeRenderer);
